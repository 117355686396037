import {SeriesBuilder, ValueMap} from '@/util/SeriesBuilder';
import {AnalyticsApiQueryResponse, Row} from '@/models/analytics-api';
import {SegmentSummery} from '@/models/segment';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from '@/models/date-context';
import {PieDataPoint, PieSeriesData} from '@ticketengine/chart/src/Models';


export class PieSeriesBuilder extends SeriesBuilder {
    private _valueMap?: ValueMap;
    private _nameMap?: ValueMap;

    constructor(
        response: AnalyticsApiQueryResponse,
        segments: SegmentSummery[],
        periods?: Period[],
        dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction,
        valueMap?: ValueMap,
        nameMap?: ValueMap,
    ) {
        super(response, segments, periods, dateContext);
        this._valueMap = valueMap;
        this._nameMap = nameMap;
    }


    public build(): PieSeriesData[] {
        const data: PieSeriesData[] = [];
        const segments = this._getSegmentsInData();

        let colorIndex = 0;
        this._getUniqueDateContextDimensionValues().forEach((value) => {
            segments.forEach((segment) => {
                data.push({
                    name: String(segment.name),
                    context: this._getSegmentContext(value),
                    data: this._getSegmentDataPoints(String(segment.name), value),
                });
                colorIndex++;
            });
        });
        return data;
    }


    private _getSegmentDataPoints(segmentName: string, dateContextDimensionValue?: string): PieDataPoint[] {
        const segmentRows = this._getFilteredRows(segmentName, dateContextDimensionValue);
        const dataPoints: PieDataPoint[] = [];
        segmentRows.forEach((row, i) => {
            dataPoints.push(this._getDataPoint(row, this._colors[i]));
        });
        return this._setDataPointPercentages(dataPoints);
    }

    private _getDataPoint(row: Row, color: string): PieDataPoint {
        if (!this._valueMap || !this._nameMap) {
            throw new Error('Missing value map(s).');
        }
        return {
            name: this._castValueToString(this._nameMap, this._getDataPointValue(this._nameMap, row)),
            color,
            value: this._castValueToNumber(this._valueMap, this._getDataPointValue(this._valueMap, row)),
            percentage: 0,
        };
    }

    private _setDataPointPercentages(dataPoints: PieDataPoint[]): PieDataPoint[] {
        const total = dataPoints.reduce((acc, val) => {
            return acc += val.value;
        }, 0);
        return dataPoints.map((dataPoint) => {
            dataPoint.percentage = Math.round((dataPoint.value / total) * 100 * 100) / 100;
            return dataPoint;
        });
    }


}
