import {KpiSeries, KpiSummery} from '@/models/kpi';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {SegmentSummery} from '@/models/segment';
import i18n from '@/i18n/index';
import {
    DateContextEvent,
    DateContextPeriod,
    DateContextTransaction, instanceOfDateContextEvent,
    instanceOfDateContextPeriod, instanceOfDateContextTransaction
} from '@/models/date-context';
import {FormatCurrencyAmount, Formatter, FormatNumber, FormatPercentage} from '@/util/Formatter';
import {FormatterFactory} from '@/util/FormatterFactory';

export class KpiSummariesBuilder {
    private response: AnalyticsApiQueryResponse;
    private dateContext: DateContextPeriod | DateContextEvent | DateContextTransaction;
    private metrics: string[];
    private segments: SegmentSummery[];

    constructor(response: AnalyticsApiQueryResponse, segments: SegmentSummery[], metrics: string[], dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction) {
        this.dateContext = dateContext;
        this.metrics = metrics;
        this.segments = segments;
        this.response = response;

    }

    public build(): KpiSummery[] {
        const data: KpiSummery[] = [];
        this.metrics.forEach((m) => {
            data.push({
                metric: m,
                name: i18n.t('metricsAndDimensions.name.' + m),
                description: i18n.t('metricsAndDimensions.description.' + m),
                // type: this.getKpiType(m),
                formatter: this.getKpiFormatter(m),
                series: this.getSeries(m),
            });
        });
        return data;
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Helpers
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // private getKpiType(name: string): KpiType {
    //     if(name === 'te.revenue') return KpiType.currencyAmount;
    //     return KpiType.int;
    // }

    private getKpiFormatter(name: string): Formatter|undefined {
        return FormatterFactory.create(name);
    }

    private getSeries(name: string): KpiSeries[] {
        const series: KpiSeries[] = [];
        this.getSegmentsInData().forEach((segment) => {
            //
            const s: KpiSeries = {
                name: segment.name,
                value: this.getValue(String(segment.name), this.getDateContextValue(), name),
                compareTo: undefined,
            };

            // set compare value
            const compareToValue = this.getDateContextCompareValue();
            if (compareToValue !== null) {
                s.compareTo = this.getValue(String(segment.name), compareToValue, name);
            }
            series.push(s);
        });
        return series;
    }

    private getSegmentsInData(): SegmentSummery[] {
        const segmentDimensionIndex = this.getDimensionIndex('te.segment');
        const uniqueSegmentIds = this.response.data.rows.map((r) => r.dimensions[segmentDimensionIndex]).filter((id, i, self) => self.indexOf(id) === i);
        return this.segments.filter((s) => uniqueSegmentIds.includes(String(s.name)));
    }

    private getValue(segmentName: string, dateContextDimensionValue: string, metric: string): string {
        const segmentDimensionIndex = this.getDimensionIndex('te.segment');
        const dateContextDimensionIndex = this.getDimensionIndex(this.getDateContextDimensionName());
        const row = this.response.data.rows.find((r) => {
            const inSegment = r.dimensions[segmentDimensionIndex] === segmentName;
            const inDateContextDimension = (dateContextDimensionValue && dateContextDimensionIndex >= 0 && r.dimensions[dateContextDimensionIndex] === dateContextDimensionValue) || dateContextDimensionIndex === -1;
            return inSegment && inDateContextDimension;
        });

        if (row) {
            return row.metrics[this.getMetricIndex(metric)];
        }
        // throw new Error('Value not found.');
        return '0';
    }

    private getDateContextValue(): string {
        if (instanceOfDateContextPeriod(this.dateContext)) {
            return this.dateContext.periodName;
        }
        if (instanceOfDateContextTransaction(this.dateContext) || instanceOfDateContextEvent(this.dateContext)) {
            return '0';
        }
        throw new Error('Unknown date context.');
    }

    private getDateContextCompareValue(): string|null {
        if (instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
            return this.dateContext.compareTo[0];
        }
        if ((instanceOfDateContextTransaction(this.dateContext) || instanceOfDateContextEvent(this.dateContext))  && this.dateContext.compareTo.length > 0) {
            return '1';
        }
        return null;
    }

    private getDateContextDimensionName(): string {
        if (this.response.data.dimensions.map((d) => d.name).includes('te.period')) {
            return 'te.period';
        }
        return 'te.dateRange';
    }

    private getDimensionIndex(name: string): number {
        return this.response.data.dimensions.map((d) => d.name).indexOf(name);
    }

    private getMetricIndex(name: string): number {
        return this.response.data.metrics.map((m) => m.name).indexOf(name);
    }

}
