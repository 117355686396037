import { render, staticRenderFns } from "./EventDetailView.vue?vue&type=template&id=402acf6c&scoped=true&"
import script from "./EventDetailView.vue?vue&type=script&lang=ts&"
export * from "./EventDetailView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402acf6c",
  null
  
)

export default component.exports