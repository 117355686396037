
    import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
    import KpiCart from '@/components/KpiCart.vue';
    import {KpiSummery} from '@/models/kpi';

    @Component({
        components: {
            KpiCart,
        }
    })
    export default class KpiList extends Vue {
        @Prop() private data!: KpiSummery[];
        @Prop() private value!: string;
        @Prop({default: false}) private isSelectable!: boolean;
        @Prop({default: 4}) private columns!: number; // number of columns
        private selectedMetric: string|null = null;
        // private columns: number = 4;

        get carts(): Array<{isSelected: boolean; data: KpiSummery;}> {
            return this.data.map((d) => {
                return {
                    isSelected: d.metric === this.selectedMetric,
                    data: d,
                }
            });
        }

        get cssStyle():string {
            return `grid-template-columns: repeat(${this.columns}, 1fr);`
        }

        public onSelect(metric: string|null): void {
            this.selectedMetric = metric;
            if(this.isSelectable) {
                this.emitSelected();
            }
        }

        @Emit('input')
        public emitSelected(): string|null {
            return this.selectedMetric;
        }

        @Watch('value')
        nameChanged(selected: string) {
            this.selectedMetric = selected
        }

        mounted() {
            this.selectedMetric = this.value;
        }
    }
