
    import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
    import BaseChart from '@/components/Chart/BaseChart.vue';
    import PieChart from '@/components/Chart/PieChart.vue';
    import {RadarChartBuilder,Chart,RadarSeries,RadarSeriesData,ScaleRadius,AxisRadar} from "@ticketengine/chart";
    import {ChartLegendSeries} from '@/models/chart';

    @Component({
        components: {
            BaseChart,
            PieChart,
        }
    })
    export default class RadarChart extends Vue {
        @Prop() private series!: RadarSeriesData[];
        @Prop({default: false}) private forcePercentage!: boolean;
        // @Ref('chart-container') readonly chartContainer!: HTMLOrSVGElement;
        @Ref('chart-container') readonly chartContainer!: Element;
        private chart?: Chart;

        get legendItems(): ChartLegendSeries[] {
            if(this.series) {
                return this.series.map((d) => {
                    return {
                        name: d.name,
                        context: d.context,
                        color: d.color,
                    }
                });
            }
            return [];
        }

        get hasSeries(): boolean {
            return this.series && this.series.length > 0;
        }

        get seriesData(): RadarSeriesData[] {
            if(this.forcePercentage) {

                return this.series.map(s => {
                    const total = s.data.reduce((accumulator, currentValue) => {
                        return accumulator += currentValue.value;
                    }, 0);
                    return {
                        name: s.name,
                        color: s.color,
                        context: s.context,
                        data: s.data.map((dp) => {
                            return {
                                axis: dp.axis,
                                value: (dp.value / total) * 100,
                            }
                        }),
                    };
                });

// console.log(this.series);
// console.log(totals);

            }
            return this.series;
        }


        private renderChart(): void {
            // clear chart if one exists
            if(this.chart) {
                this.chart.clear();
            }

            // let size = this.$el.clientWidth;
            // this.chart = new Chart(this.chartContainer, size, size, {top: 60, right: 60, bottom: 60, left: 60});
            //
            // const scale = new ScaleRadius(this.chart);
            // // scale.setDomain(0, 2503);
            // scale.setDomain(0, 40);
            // const axis = new AxisRadar(scale, ['Regular', 'Special', 'Pro', 'Education', 'Masterclass'], 5, '%');
            // this.chart.addAxis(axis);
            //
            // this.seriesData.forEach((s) => {
            //     if(this.chart) {
            //         const radarSeries = new RadarSeries(s, scale);
            //         this.chart.addSeries(radarSeries);
            //     }
            // })


            if(this.hasSeries) {
                const size = this.$el.clientWidth;
                const margin = {top: 60, right: 60, bottom: 60, left: 60};
                const builder = new RadarChartBuilder(this.seriesData, this.chartContainer, size, size, margin);
                this.chart = builder.build();
            }
        }

        @Watch('series')
        seriesChanged(newSeries: RadarSeriesData[]) {
            this.renderChart();
        }

        mounted(): void {
            this.$nextTick(() => {
                this.renderChart();
            });
        }
    }
