
import {Component, Vue} from 'vue-property-decorator';
import BaseLayout from '@/views/BaseLayout.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import Panel from '@/components/Panel.vue';

import loading from '@/util/Loading';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {DateContextTransaction, DateContextType} from '@/models/date-context';

// const namespace: string = 'event';

@Component({
  components: {
    BaseLayout,
    ViewHeader,
    Panel,

  }
})
export default class TestView extends Vue {

  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
  }



  getReportConfig(): any {


    return {
      id: '1',
      title: 'My 1st report',
      items: [
        {
          id: '2',
          type: 'row',
          items: [
            {
              id: '3',
              type: 'column',
              items: [
                {
                  id: '4',
                  type: 'LineChart',
                  dataSource: {type:'AnalyticsApi', name: 'OrderLines'},
                  // chart: {type: 'LineChart'},
                  // dimensions: ['te.transactionDate', 'te.segment'],
                  dimensions: ['te.transactionDate'],
                  metrics: ['te.sold'],
                  filters: [],
                  useSegments: true,
                  useDateContext: true,
                  includeEmptyRows: false,
                  title: 'Sold items',
                }
              ]
            },
            {
              id: '5',
              type: 'column',
              items: [
                {
                  id: '6',
                  type: 'BarChart',
                  dataSource: {type:'AnalyticsApi', name: 'OrderLines'},
                  dimensions: ['te.priceType', 'te.segment', 'te.dateRange'],
                  metrics: ['te.sold'],
                  filters: [],
                  useSegments: true,
                  useDateContext: true,
                  includeEmptyRows: false,
                  title: 'Sales per price type',
                }
              ]
            }
          ]
        }
      ]
    }


  }


}
