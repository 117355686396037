
    import { Component, Prop, Vue, Ref, Watch } from 'vue-property-decorator';
    import BaseChart from '@/components/Chart/BaseChart.vue';
    import PieChart from '@/components/Chart/PieChart.vue';
    import {SeriesData,Chart,PieSeriesData} from "@ticketengine/chart";
    import {ChartLegendSeries} from '@/models/chart';

    @Component({
        components: {
            BaseChart,
            PieChart,
        }
    })
    export default class MultiPieChart extends Vue {
        @Prop() private series!: PieSeriesData[];
        @Ref('chart-container') readonly chartContainer!: HTMLOrSVGElement;
        private chart?: Chart;

        get legendItems(): ChartLegendSeries[] {
            if(this.series && this.series[0]) {
                return this.series[0].data.map((d) => {
                    return {
                        name: d.name,
                        context: null,
                        color: d.color,
                    }
                });
            }
            return [];
        }

        get hasSeries(): boolean {
            return this.series && this.series.length > 0;
        }


        // private renderChart(): void {
        //
        // }

        @Watch('series')
        seriesChanged(newSeries: SeriesData[]) {
            // this.renderChart();
        }

        mounted(): void {
            this.$nextTick(() => {
                // this.renderChart();
            });
        }
    }
