
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import BaseLayout from '@/views/BaseLayout.vue';
    import ViewHeader from '@/components/ViewHeader.vue';
    import SegmentContext from '@/components/SegmentContext.vue';
    import DateContext from '@/components/DateContext.vue';
    import Panel from '@/components/Panel.vue';
    import LineChart from '@/components/Chart/LineChart.vue';
    import BarChart from '@/components/Chart/BarChart.vue';
    import HeatMapChart from '@/components/Chart/HeatMapChart.vue';
    import {CartesianSeriesBuilder} from "@/util/CartesianSeriesBuilder";
    import {
        DateContextEvent,
        DateContextPeriod,
        DateContextTransaction,
        instanceOfDateContextPeriod,
        Period
    } from "@/models/date-context";
    import {SegmentSummery} from '@/models/segment';
    import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
    import {SeriesData, BarSeriesData} from '@ticketengine/chart';
    import {MapType, ValueMap, ValueType} from "@/util/SeriesBuilder";
    import {BarSeriesBuilder} from '@/util/BarSeriesBuilder';
    import loading from '@/util/Loading';
    const namespace: string = 'event';

    @Component({
        components: {
            BaseLayout,
            ViewHeader,
            SegmentContext,
            DateContext,
            Panel,
            LineChart,
            BarChart,
            HeatMapChart,
        }
    })
    export default class EventOccupancyView extends Vue {
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Getter('selectedSegments') selectedSegments?: SegmentSummery[];
        @Getter('periods') periods?: Period[];
        @Getter('visitTimeData', { namespace }) visitTimeData?: AnalyticsApiQueryResponse|null;
        @Getter('occupancyTimeSeriesData', { namespace }) occupancyTimeSeriesData?: AnalyticsApiQueryResponse|null;
        @Getter('occupancyPerEventCategoryData', { namespace }) occupancyPerEventCategoryData?: AnalyticsApiQueryResponse|null;
        @Getter('occupancyPerTimeData', { namespace }) occupancyPerTimeData?: AnalyticsApiQueryResponse|null;
        @Action('fetchPeriods') fetchPeriods: any;
        @Action('fetchDateContext') fetchDateContext: any;
        @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        @Action('fetchOccupancyTimeSeriesData', {namespace}) fetchOccupancyTimeSeriesData: any;
        @Action('fetchOccupancyPerEventCategoryData', {namespace}) fetchOccupancyPerEventCategoryData: any;
        @Action('fetchOccupancyPerTimeData', {namespace}) fetchOccupancyPerTimeData: any;

        get isLoading(): boolean {
            return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
        }

        get dailyOccupancySeries(): BarSeriesData[] {
            if(this.occupancyTimeSeriesData && this.selectedSegments && this.periods) {
                const valueMap: ValueMap = {mapType: MapType.metric, valueType: ValueType.float, field: 'te.eventOccupancyRate'};
                const dateDimension = instanceOfDateContextPeriod(this.dateContext) ? 'te.period' : 'te.dateRange';
                const builder = new BarSeriesBuilder(this.occupancyTimeSeriesData, valueMap, ['te.eventStartDayOfWeek', dateDimension], this.selectedSegments, this.periods, this.dateContext);
                // return builder.build();
                const data = builder.build();
                return data.map((d) => {
                    switch(d.name) {
                        case '0':
                            d.name = 'Sun';
                            break;
                        case '1':
                            d.name = 'Mon';
                            break;
                        case '2':
                            d.name = 'Tue';
                            break;
                        case '3':
                            d.name = 'Wed';
                            break;
                        case '4':
                            d.name = 'Thu';
                            break;
                        case '5':
                            d.name = 'Fri';
                            break;
                        case '6':
                            d.name = 'Sat';
                            break;
                    }
                    return d
                });
            }
            return [];
        }

        get eventCategorySeries(): BarSeriesData[] {
            if(this.occupancyPerEventCategoryData && this.selectedSegments && this.periods) {
                const valueMap: ValueMap = {mapType: MapType.metric, valueType: ValueType.float, field: 'te.eventOccupancyRate'};
                const dateDimension = instanceOfDateContextPeriod(this.dateContext) ? 'te.period' : 'te.dateRange';
                const builder = new BarSeriesBuilder(this.occupancyPerEventCategoryData, valueMap, ['te.eventCategory', dateDimension], this.selectedSegments, this.periods, this.dateContext);
                return builder.build();
            }
            return [];
        }

        get xValuesVisitTimeSeries(): string[] {
            return ['Mo', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        }

        get yValuesVisitTimeSeries(): string[] {
            return ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'].reverse();
        }

        get perTimSeries(): SeriesData[] {
            if(this.occupancyPerTimeData && this.periods && this.selectedSegments) {
                const valueMapX: ValueMap = {mapType: MapType.dimension, valueType: ValueType.string, field: 'te.eventStartDayOfWeek'};
                const valueMapY: ValueMap = {mapType: MapType.dimension, valueType: ValueType.string, field: 'te.eventStartHour'};
                const valueMapZ: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.sold'};
                const seriesBuilder = new CartesianSeriesBuilder(this.occupancyPerTimeData, this.selectedSegments, this.periods, this.dateContext, valueMapX, valueMapY, valueMapZ);
                const series = seriesBuilder.build();

                // map values
                return series.map((s) => {
                    s.unit = String(this.$t('metricsAndDimensions.name.te.sold')).toLowerCase();
                    s.data = s.data.map((d) => {
                        d.x = String(this.$t('time.dayOfWeekNameAbbreviation.' + String(d.x))); // map day of week names
                        d.y = d.y + ':00';
                        return d;
                    });
                    return s;
                });
            }
            return [];
        }


        public fetchData(): void {
            this.fetchOccupancyTimeSeriesData();
            this.fetchOccupancyPerEventCategoryData();
            this.fetchOccupancyPerTimeData();
        }


        async mounted() {
            await this.fetchSegmentSummaries();
            await this.fetchPeriods();
            this.fetchDateContext();
            this.fetchData();
        }

    }
