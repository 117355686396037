
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import BaseLayout from '@/views/BaseLayout.vue';
    import ViewHeader from '@/components/ViewHeader.vue';
    import SegmentContext from '@/components/SegmentContext.vue';
    import DateContext from '@/components/DateContext.vue';
    import Panel from '@/components/Panel.vue';
    import DimensionTable from '@/components/DimensionTable.vue';
    import LineChart from '@/components/Chart/LineChart.vue';
    import IconButton from '@/components/IconButton.vue';
    import {DimensionTableDimension, DimensionTableLink, DimensionTableMetric, DimensionTableRow} from "@/models/table";
    import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
    import {SegmentSummery} from '@/models/segment';
    import {
        DateContextEvent,
        DateContextPeriod,
        DateContextTransaction,
        instanceOfDateContextPeriod, instanceOfDateContextTransaction,
        Period,
        DateRange
    } from "@/models/date-context";
    import {DimensionTableSeriesBuilder} from "@/util/DimensionTableSeriesBuilder";
    import {FormatCurrencyAmount, FormatNumber, FormatPercentage} from "@/util/Formatter";
    import {FormatterFactory} from "@/util/FormatterFactory";
    import {SeriesData} from '@ticketengine/chart';
    import {MapType, ValueMap, ValueType} from "@/util/SeriesBuilder";
    import {CartesianSeriesBuilder} from '@/util/CartesianSeriesBuilder';
    import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
    import exportService from '@/services/ExportService';
    import dateService from '@/services/DateService';
    import loading from '@/util/Loading';
    import DatePicker from '@/components/Input/DatePicker.vue';
    const namespace: string = 'event';

    @Component({
        components: {
            BaseLayout,
            ViewHeader,
            SegmentContext,
            DateContext,
            Panel,
            DimensionTable,
            LineChart,
            IconButton,
            DatePicker,
        }
    })
    export default class EventListView extends Vue {
        @Getter('listEventData', { namespace }) listEventData?: AnalyticsApiQueryResponse|null;
        @Getter('overviewTimeSeriesData', { namespace }) overviewTimeSeriesData?: AnalyticsApiQueryResponse|null;
        @Getter('periods') periods!: Period[];
        @Getter('selectedSegments') selectedSegments!: SegmentSummery[];
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Action('fetchListEventsData', { namespace }) fetchListEventsData: any;
        @Action('fetchOverviewTimeSeriesData', { namespace }) fetchOverviewTimeSeriesData: any;
        @Action('fetchPeriods') fetchPeriods: any;
        @Action('fetchDateContext') fetchDateContext: any;
        @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        private eventDateFilter: Date|null = null;

        get isLoading(): boolean {
            return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
        }

        get rows(): DimensionTableRow[] {
            if(this.listEventData) {
                const builder = new DimensionTableSeriesBuilder(this.listEventData, this.selectedSegments, this.dimensions.map(d => d.name), this.metrics.map(m => m.name), this.periods, this.dateContext, this.metadata);
                return builder.build();
            }
            return [];
        }

        get dimensions(): DimensionTableDimension[] {
            const dimensions: DimensionTableDimension[] = [];
            dimensions.push({name: 'te.eventOutline', label: ''});
            // dimensions.push({name: 'te.eventTitle', label: ''});
            // dimensions.push({name: 'te.eventLocation', label: ''});
            // dimensions.push({name: 'te.eventStartDate', label: ''});
            if(this.dateContext && instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
                dimensions.push({name: 'te.period', label: this.$t('metricsAndDimensions.name.te.period')});
            }
            if(this.dateContext && instanceOfDateContextTransaction(this.dateContext) && this.dateContext.compareTo.length > 0) {
                dimensions.push({name: 'te.dateRange', label: this.$t('metricsAndDimensions.name.te.dateRange')});
            }
            if(this.selectedSegments && this.selectedSegments.length > 1) {
                dimensions.push({name: 'te.segment', label: this.$t('metricsAndDimensions.name.te.segment')});
            }
            return dimensions;
        }

        get metrics(): DimensionTableMetric[] {
            const metrics = ['te.sold', 'te.reserved', 'te.eventCapacity', 'te.eventOccupancyRate', 'te.revenue', 'te.uniqueCustomers', 'te.salesVelocityDays'];
            return metrics.map((m) => {
                return {name: m, formatter: FormatterFactory.create(m),label: this.$t('metricsAndDimensions.name.' + m), description: this.$t('metricsAndDimensions.description.' + m)};
            });
        }

        get metadata(): string[] {
            return ['te.eventId'];
            // return ['te.eventId', 'te.eventLocation', 'te.eventStartDates'];
        }

        get tableLink(): DimensionTableLink {
            return {
                routeName: 'event-detail',
                // params: [{paramKey: 'id', dimension: 'te.eventOutline'}]
                params: [{paramKey: 'id', metadata: 'te.eventId'}]
            }
        }

        // get lineChartSeries(): SeriesData[] {
        //     if(this.overviewTimeSeriesData && this.selectedSegments && this.periods) {
        //         const valueMapX: ValueMap = {mapType: MapType.dimension, valueType: ValueType.date, field: 'te.transactionDate'};
        //         const valueMapY: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.sold'};
        //         const seriesBuilder = new CartesianSeriesBuilder(this.overviewTimeSeriesData, this.selectedSegments, this.periods, this.dateContext, valueMapX, valueMapY);
        //         return seriesBuilder.build();
        //     }
        //     return [];
        // }

        public exportToCsv(): void {
            if(this.listEventData && this.selectedSegments) {
                const builder = new CsvSeriesBuilder(this.listEventData, this.selectedSegments, this.dimensions.map(d => d.name), this.metrics.map(m => m.name), this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event.csv');
            }
        }

        public fetchData(): void {
            this.fetchListEventsData({eventStartDate: this.eventDateFilter ? dateService.formatDate(this.eventDateFilter, 'YYYYMMDD') : null});
            // this.fetchOverviewTimeSeriesData({timeDimension: 'te.transactionDate', metric: 'te.sold'});
        }

        public setEventDateFilter(d: Date|null): void {
            this.eventDateFilter = d;
            this.fetchData();
        }

        async mounted() {
            await this.fetchSegmentSummaries();
            await this.fetchPeriods();
            this.fetchDateContext();
            this.fetchData();
        }

    }
