import {AnalyticsApiQueryResponse} from '@/models/analytics-api';


class QueryResponseValidator {

    public receivedData(response: AnalyticsApiQueryResponse): boolean {
        if(response && response.data && response.data.rows && response.data.rows.length > 0) {
            return true;
        }
        return false;
    }

}

export default new QueryResponseValidator();
