
    import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
    import BaseChart from "@/components/Chart/BaseChart.vue";
    import {Chart, HeatMapSeries, ScaleGradient, ScaleX, ScaleY, AxisX, AxisY, ScaleType, Margin, SeriesData} from "@ticketengine/chart";

    @Component({
        components: {
            BaseChart,
        }
    })
    export default class HeatMapChart extends Vue {
        @Prop() private series!: SeriesData;
        @Prop() private width!: number;
        @Prop() private height!: number;
        @Prop() private margin!: Margin;
        @Prop() private xValues!: string[];
        @Prop() private yValues!: string[];
        // @Ref('chart-container') readonly chartContainer!: HTMLOrSVGElement;
        @Ref('chart-container') readonly chartContainer!: Element;
        private chart?: Chart;



        get seriesData(): SeriesData {
            return this.series;
        }


        private renderChart(): void {
            // clear chart if one exists
            if(this.chart) {
                this.chart.clear();
            }

            const width = this.width ? this.width : this.$el.clientWidth;
            const height = this.height ? this.height : this.$el.clientWidth;
            const margin = this.margin ? this.margin : {top: 30, right: 20, bottom: 70, left: 45};
            this.chart = new Chart(this.chartContainer, width, height, margin);

            const series = new HeatMapSeries(this.seriesData, this.xValues, this.yValues);
            this.chart.addSeries(series);
        }

        @Watch('series')
        seriesChanged(newSeries: SeriesData) {
            this.renderChart();
        }

        mounted(): void {
            this.$nextTick(() => {
                this.renderChart();
            });
        }
    }
