
    import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
    import {KpiSeries, KpiSummery} from "@/models/kpi";
    import {Formatter, FormatPercentage} from '@/util/Formatter';
    import Icon from '@/components/Icon.vue';

    @Component({
        components: {
            Icon,
        }
    })
    export default class KpiCart extends Vue {
        @Prop() private data!: KpiSummery;
        @Prop({default: false}) private isSelectable!: boolean;
        @Prop({default: false}) private isSelected!: boolean;

        get showSeriesNames(): boolean {
            return this.data.series.length > 1;
        }

        public getSeries(data: KpiSummery): KpiSeries[] {
            if(data.series && data.series.length > 0) {
                return data.series;
            }
            return [{
                name: '',
                value: '0'
            }];
        }

        public getKpiValue(value: string, compareValue?: string): string {
            if(value && compareValue && (value === '0' || compareValue === '0' || value === '0.0' || compareValue === '0.0' || value === '0.00' || compareValue === '0.00')) {
                return '~';
            }
            if(compareValue) {
                const formatter = new FormatPercentage(1);
                const percentage = ((parseFloat(value) / parseFloat(compareValue)) - 1);
                return formatter.format(percentage);
            }
            return this.formatKpiValue(value);
        }

        public formatKpiValue(value: string): string {
            if(this.data.formatter) {
                return this.data.formatter.format(value);
            }
            return value;
        }

        public valueCssClass(value: string, compareValue?: string): string {
            if(compareValue && parseFloat(value) > parseFloat(compareValue)) {
                return 'grow';
            }
            if(compareValue && parseFloat(value) < parseFloat(compareValue)) {
                return 'shrink';
            }
            return '';
        }


        public onSelect(): void {
            if(this.isSelectable) {
                this.emitSelected();
            }
        }

        @Emit('select')
        public emitSelected(): string {
            return this.data.metric;
        }

    }
