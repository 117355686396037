
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BaseLayout from '@/views/BaseLayout.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import SegmentContext from '@/components/SegmentContext.vue';
import DateContext from '@/components/DateContext.vue';
import Panel from '@/components/Panel.vue';
import DimensionTable from '@/components/DimensionTable.vue';
import LineChart from '@/components/Chart/LineChart.vue';
import IconButton from '@/components/IconButton.vue';
import {
  DimensionTableDimension,
  DimensionTableLink,
  DimensionTableMetadataColumn,
  DimensionTableMetric,
  DimensionTableRow
} from '@/models/table';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {
  DateContextEvent,
  DateContextPeriod,
  DateContextTransaction,
  instanceOfDateContextPeriod, instanceOfDateContextTransaction,
  Period,
  DateRange
} from "@/models/date-context";
import {DimensionTableSeriesBuilder} from "@/util/DimensionTableSeriesBuilder";
import {FormatterFactory} from "@/util/FormatterFactory";
import loading from '@/util/Loading';
import DatePicker from '@/components/Input/DatePicker.vue';
import dateService from '@/services/DateService';
import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
import exportService from '@/services/ExportService';
import {FormatDateString} from '@/util/Formatter';
const namespace: string = 'event';

@Component({
  components: {
    BaseLayout,
    ViewHeader,
    SegmentContext,
    DateContext,
    Panel,
    DimensionTable,
    LineChart,
    IconButton,
    DatePicker,
  }
})
export default class EventListView extends Vue {
  @Getter('eventPriceTypesData', { namespace }) eventPriceTypesData?: AnalyticsApiQueryResponse|null;
  @Getter('periods') periods!: Period[];
  @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
  @Action('fetchEventPriceTypeData', { namespace }) fetchEventPriceTypeData: any;
  @Action('fetchPeriods') fetchPeriods: any;
  @Action('fetchDateContext') fetchDateContext: any;
  private eventDateFilter: Date|null = null;

  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
  }

  get rows(): DimensionTableRow[] {
    if(this.eventPriceTypesData) {
      const builder = new DimensionTableSeriesBuilder(this.eventPriceTypesData, [], this.dimensions.map(d => d.name), this.metrics.map(m => m.name), this.periods, this.dateContext, this.metadata);
      return builder.build();
    }
    return [];
  }

  get dimensions(): DimensionTableDimension[] {
    const dimensions: DimensionTableDimension[] = [
        {name: 'te.eventTitle', label: this.$t('metricsAndDimensions.name.te.eventTitle')},
        // {name: 'te.priceType', label: this.$t('metricsAndDimensions.name.te.priceType')},
    ];
    if(this.dateContext && instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
      dimensions.push({name: 'te.period', label: this.$t('metricsAndDimensions.name.te.period')});
    }
    if(this.dateContext && instanceOfDateContextTransaction(this.dateContext) && this.dateContext.compareTo.length > 0) {
      dimensions.push({name: 'te.dateRange', label: this.$t('metricsAndDimensions.name.te.dateRange')});
    }
    return dimensions;
  }

  get metrics(): DimensionTableMetric[] {
    const metrics = ['te.sold', 'te.reserved', 'te.revenue'];
    return metrics.map((m) => {
      return {name: m, formatter: FormatterFactory.create(m),label: this.$t('metricsAndDimensions.name.' + m), description: this.$t('metricsAndDimensions.description.' + m)};
    });
  }

  get metadata(): string[] {
    return ['te.eventId', 'te.eventStartDateHourMinute', 'te.eventLocation', 'te.priceType'];
  }

  get metadataColumns(): DimensionTableMetadataColumn[] {
    const dateFormatter = new FormatDateString('YYYYMMDDHHmm', 'MMM D YYYY, HH:mm');
    return [
      // {name: 'te.eventId', label: this.$t('metricsAndDimensions.name.te.eventId')},
      {name: 'te.eventStartDateHourMinute', label: this.$t('metricsAndDimensions.name.te.eventStartDate'), formatter: dateFormatter},
      {name: 'te.eventLocation', label: this.$t('metricsAndDimensions.name.te.eventLocation')},
      {name: 'te.priceType', label: this.$t('metricsAndDimensions.name.te.priceType')},
    ]; // metadata that needs to be displayed in a column
  }

  get tableLink(): DimensionTableLink {
    return {
      routeName: 'event-detail',
      params: [{paramKey: 'id', metadata: 'te.eventId'}]
    }
  }

  public exportToCsv(): void {
    if(this.eventPriceTypesData) {
      const builder = new CsvSeriesBuilder(this.eventPriceTypesData, [], this.dimensions.map(d => d.name), this.metrics.map(m => m.name), this.periods, this.dateContext, this.metadataColumns.map((c) => c.name));
      exportService.csv(builder.build(), 'event-price-types.csv');
    }
  }

  public fetchData(): void {
    this.fetchEventPriceTypeData({eventStartDate: this.eventDateFilter ? dateService.formatDate(this.eventDateFilter, 'YYYYMMDD') : null});
  }

  public setEventDateFilter(d: Date|null): void {
    this.eventDateFilter = d;
    this.fetchData();
  }

  async mounted() {
    await this.fetchPeriods();
    this.fetchDateContext();
    this.fetchData();
  }

}
