
    import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
    import {ChartLegendSeries} from '@/models/chart';

    @Component
    export default class BaseChart extends Vue {
        @Prop() private series!: ChartLegendSeries[];
        @Prop({default: false}) private noData!: boolean;

    }
