
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import moment from 'moment';
    import BaseLayout from '@/views/BaseLayout.vue';
    import ViewHeader from '@/components/ViewHeader.vue';
    import SegmentContext from '@/components/SegmentContext.vue';
    import DateContext from '@/components/DateContext.vue';
    import Panel from '@/components/Panel.vue';
    import LineChart from '@/components/Chart/LineChart.vue';
    import BarChart from '@/components/Chart/BarChart.vue';
    import KpiList from '@/components/KpiList.vue';
    import BasicTable from '@/components/BasicTable.vue';
    import MultiPieChart from '@/components/Chart/MultiPieChart.vue';
    import IconButton from '@/components/IconButton.vue';
    import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
    import {SeriesData, PieSeriesData, BarSeriesData} from '@ticketengine/chart';
    import {MapType, ValueMap, ValueType} from "@/util/SeriesBuilder";
    import {CartesianSeriesBuilder} from '@/util/CartesianSeriesBuilder';
    import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
    import {BasicTableSeriesBuilder} from '@/util/BasicTableSeriesBuilder';
    import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from "@/models/date-context";
    import {SegmentSummery} from '@/models/segment';
    import {KpiSummery} from '@/models/kpi';
    import {KpiSummariesBuilder} from '@/util/KpiSummariesBuilder';
    import {PieSeriesBuilder} from '@/util/PieSeriesBuilder';
    import {TableColumn} from '@/models/table';
    import {FormatCurrencyAmount} from '@/util/Formatter';
    import {Currency} from '@/models/currency';
    import {BarSeriesBuilder} from '@/util/BarSeriesBuilder';
    import exportService from '@/services/ExportService';
    import loading from '@/util/Loading';
    const namespace: string = 'event';

    @Component({
        components: {
            BaseLayout,
            ViewHeader,
            SegmentContext,
            DateContext,
            Panel,
            LineChart,
            BarChart,
            KpiList,
            MultiPieChart,
            BasicTable,
            IconButton,
        }
    })
    export default class EventDetailView extends Vue {
        @Getter('detailKpiData', { namespace }) detailKpiData?: AnalyticsApiQueryResponse|null;
        @Getter('detailTimeSeriesData', { namespace }) detailTimeSeriesData?: AnalyticsApiQueryResponse|null;
        @Getter('detailNewVsReturningData', { namespace }) detailNewVsReturningData?: AnalyticsApiQueryResponse|null;
        @Getter('detailPriceTypesData', { namespace }) detailPriceTypesData?: AnalyticsApiQueryResponse|null;
        @Getter('detailPriceTypesTableData', { namespace }) detailPriceTypesTableData?: AnalyticsApiQueryResponse|null;
        @Getter('detailCustomersData', { namespace }) detailCustomersData?: AnalyticsApiQueryResponse|null;
        @Getter('periods') periods!: Period[];
        @Getter('selectedSegments') selectedSegments!: SegmentSummery[];
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Getter('dateDimensionName') dateDimensionName?: string;
        @Getter('defaultCurrency') defaultCurrency?: Currency;
        @Action('fetchDetailKpiData', { namespace }) fetchDetailKpiData: any;
        @Action('fetchDetailTimeSeriesData', { namespace }) fetchDetailTimeSeriesData: any;
        @Action('fetchDetailNewVsReturningData', { namespace }) fetchDetailNewVsReturningData: any;
        @Action('fetchDetailPriceTypesData', { namespace }) fetchDetailPriceTypesData: any;
        @Action('fetchDetailPriceTypesTableData', { namespace }) fetchDetailPriceTypesTableData: any;
        @Action('fetchDetailCustomersData', { namespace }) fetchDetailCustomersData: any;
        @Action('fetchPeriods') fetchPeriods: any;
        @Action('fetchDateContext') fetchDateContext: any;
        @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        private timeSeriesMetric: string = 'te.sold';

        get isLoading(): boolean {
            return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
        }

        get viewTitle(): string {
            return String(this.$t('event.detail')) + ' - ' + this.$route.params.id
        }

        get primaryKpi(): KpiSummery[] {
            if(this.detailKpiData && this.selectedSegments && this.dateContext) {
                const metrics = ['te.sold', 'te.reserved', 'te.abandoned', 'te.revenue'];
                const kpiBuilder = new KpiSummariesBuilder(this.detailKpiData, this.selectedSegments, metrics, this.dateContext);
                return kpiBuilder.build();
            }
            return [];
        }

        get secondaryKpi(): KpiSummery[] {
            if(this.detailKpiData && this.selectedSegments && this.dateContext) {
                // const metrics = ['te.visitedEvents', 'te.visitedPeriods', 'te.salesVelocityDays', 'te.groupSize', 'te.eventOccupancyRate', 'te.transactionsPerCustomer'];
                const metrics = ['te.eventOccupancyRate', 'te.eventCapacity', 'te.groupSize', 'te.salesVelocityDays'];
                const kpiBuilder = new KpiSummariesBuilder(this.detailKpiData, this.selectedSegments, metrics, this.dateContext);
                return kpiBuilder.build();
            }
            return [];
        }

        get lineChartSeries(): SeriesData[] {
            if(this.detailTimeSeriesData && this.selectedSegments && this.periods) {
                const valueMapX: ValueMap = {mapType: MapType.dimension, valueType: ValueType.date, field: 'te.transactionDate'};
                const valueMapY: ValueMap = {mapType: MapType.metric, valueType: ['te.revenue', 'te.eventOccupancyRate'].includes(this.timeSeriesMetric) ? ValueType.float : ValueType.int, field: this.timeSeriesMetric};
                const seriesBuilder = new CartesianSeriesBuilder(this.detailTimeSeriesData, this.selectedSegments, this.periods, this.dateContext, valueMapX, valueMapY);
                return seriesBuilder.build();
            }
            return [];
        }

        public onSelectTimeSeriesMetric(metric: string): void {
            this.timeSeriesMetric = metric;
            this.getTimeSeriesData();
        }

        public getTimeSeriesData(): void {
            this.fetchDetailTimeSeriesData({timeDimension: 'te.transactionDate', metric: this.timeSeriesMetric, eventId: this.$route.params.id});
        }

        get newVsReturningSeries(): PieSeriesData[] {
            if(this.detailNewVsReturningData && this.selectedSegments && this.periods) {
                const valueMap: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.uniqueCustomers'};
                const nameMap: ValueMap = {mapType: MapType.dimension, valueType: ValueType.string, field: 'te.customerType'};
                const pieSeriesBuilder = new PieSeriesBuilder(this.detailNewVsReturningData, this.selectedSegments, this.periods, this.dateContext, valueMap, nameMap);
                return pieSeriesBuilder.build();
            }
            return [];
        }

        get priceTypeSeries(): BarSeriesData[] {
            if(this.detailPriceTypesData && this.selectedSegments && this.periods) {
                const valueMap: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.sold'};
                const builder = new BarSeriesBuilder(this.detailPriceTypesData, valueMap, ['te.priceType', 'te.segment'], this.selectedSegments, this.periods, this.dateContext);
                return builder.build();
            }
            return [];
        }

        get priceTypeTableSeries(): BarSeriesData[] {
            if(this.detailPriceTypesTableData && this.selectedSegments && this.periods) {
                const builder = new BasicTableSeriesBuilder(this.detailPriceTypesTableData);
                return builder.build();
            }
            return [];
        }

        get priceTypeTableColumns(): TableColumn[] {
            const priceFormatter = new FormatCurrencyAmount(this.defaultCurrency ? this.defaultCurrency.symbol : '€', this.defaultCurrency ? this.defaultCurrency.exponent : 2);
            return [
                {key: 'priceType', label: this.$t('metricsAndDimensions.name.te.priceType')},
                {key: 'sold', label: this.$t('metricsAndDimensions.name.te.sold')},
                {key: 'reserved', label: this.$t('metricsAndDimensions.name.te.reserved')},
                {key: 'revenueExTax', label: this.$t('metricsAndDimensions.name.te.revenueExTax'), format: priceFormatter.format.bind(priceFormatter)},
                {key: 'revenue', label: this.$t('metricsAndDimensions.name.te.revenue'), format: priceFormatter.format.bind(priceFormatter)},
            ];
        }

        get attendeesSeries(): any[] {
            if(this.detailCustomersData) {
                const builder = new BasicTableSeriesBuilder(this.detailCustomersData);
                return builder.build();
            }
            return [];
        }

        get attendeesColumns(): TableColumn[] {
            const priceFormatter = new FormatCurrencyAmount(this.defaultCurrency ? this.defaultCurrency.symbol : '€', this.defaultCurrency ? this.defaultCurrency.exponent : 2);
            const dateFormatter = (v:string) => { return moment(v, 'YYYYMMDDHHmm').format('D MMM YYYY @ HH:mm'); };
            return [
                // {key: 'customer', label: '', link: {routeName: 'customer-detail', params: [{paramKey: 'id', valueKey: 'customerId'}]}},
                {key: 'customer', label: this.$t('common.customer')},
                {key: 'priceType', label: this.$t('metricsAndDimensions.name.te.priceType')},
                {key: 'sold', label: this.$t('common.quantity')},
                {key: 'revenue', label: this.$t('common.price'), format: priceFormatter.format.bind(priceFormatter)},
                {key: 'transactionDateHourMinute', label: this.$t('common.date'), format: dateFormatter},
            ];
        }

        public exportTimeSeriesToCsv(): void {
            if(this.detailTimeSeriesData && this.selectedSegments && this.dateDimensionName) {
                const builder = new CsvSeriesBuilder(this.detailTimeSeriesData, this.selectedSegments, ['te.transactionDate', 'te.segment', this.dateDimensionName], [this.timeSeriesMetric], this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event-time-series.csv');
            }
        }

        public exportNewVsReturningToCsv(): void {
            if(this.detailNewVsReturningData && this.selectedSegments && this.dateDimensionName) {
                const builder = new CsvSeriesBuilder(this.detailNewVsReturningData, this.selectedSegments, ['te.customerType', 'te.segment', this.dateDimensionName], ['te.uniqueCustomers'], this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event-new-vs-returning.csv');
            }
        }

        public exportPriceTypeToCsv(): void {
            if(this.detailPriceTypesData && this.selectedSegments && this.dateDimensionName) {
                const builder = new CsvSeriesBuilder(this.detailPriceTypesData, this.selectedSegments, ['te.priceType', 'te.segment', this.dateDimensionName], ['te.sold'], this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event-sales-per-price-type.csv');
            }
        }

        public exportPriceTypeTableToCsv(): void {
            if(this.detailPriceTypesTableData && this.selectedSegments && this.dateDimensionName) {
                const builder = new CsvSeriesBuilder(this.detailPriceTypesTableData, this.selectedSegments, ['te.priceType'], ['te.sold', 'te.reserved', 'te.revenue', 'te.revenueExTax'], this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event-sales-aggregated-per-price-type.csv');
            }
        }

        // public exportAttendeesToCsv(): void {
        //     if(this.attendeesSeries && this.selectedSegments) {
        //         const builder = new CsvSeriesBuilder(this.attendeesSeries, this.selectedSegments, ['te.transactionDate', 'te.segment', 'te.period'], ['te.eventOccupancyRate'], this.periods, this.dateContext);
        //         exportService.csv(builder.build(), 'event-attendees.csv');
        //     }
        // }

        public fetchData(): void {
            this.fetchDetailKpiData({eventId: this.$route.params.id});
            this.fetchDetailNewVsReturningData({eventId: this.$route.params.id});
            this.fetchDetailPriceTypesData({eventId: this.$route.params.id});
            this.fetchDetailPriceTypesTableData({eventId: this.$route.params.id});
            this.fetchDetailCustomersData({eventId: this.$route.params.id});
            this.getTimeSeriesData();
        }

        async mounted() {
            await this.fetchSegmentSummaries();
            await this.fetchPeriods();
            this.fetchDateContext();
            this.fetchData();
        }

    }
