import {RowQueryByDimension, SeriesBuilder, ValueMap} from '@/util/SeriesBuilder';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {SegmentSummery} from '@/models/segment';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from '@/models/date-context';
import {BarSeriesData, BarDataPoint, PieDataPoint} from '@ticketengine/chart';


export class BarSeriesBuilder extends SeriesBuilder {
    private _valueMap: ValueMap;
    private dimension: string[];

    constructor(
        response: AnalyticsApiQueryResponse,
        valueMap: ValueMap,
        dimension: string[],
        segments?: SegmentSummery[],
        periods?: Period[],
        dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction,
    ) {
        super(response, segments, periods, dateContext);
        this.dimension = dimension;
        this._valueMap = valueMap;

        if (this.dimension.length < 2) {
            throw new Error('At least 2 dimensions needed.');
        }
    }


    public build(): BarSeriesData[] {
        const data: BarSeriesData[] = [];
        this._getUniqueDimensionValues(this.dimension[0]).forEach((value, i) => {
            data.push({
                id: this._generateId(),
                index: i,
                name: this._formatDimensionValue(value, this.dimension[0]),
                data: this._getDataPoints(value),
            });
        });

        return data;
    }


    private _getDataPoints(primaryDimensionValue: string): BarDataPoint[] {
        const dataPoints: BarDataPoint[] = [];
        let colorIndex = 0;

        const cartesianProduct = this._getDimensionsValuesCartesianProduct(this.dimension.slice(1)); // cartesian product on secondary paymentMethodDimensions
        cartesianProduct.forEach((values, i) => {
            const rowQuery: RowQueryByDimension[] = [[{name: this.dimension[0], value: primaryDimensionValue}], values.map((v, j) => ({name: this.dimension.slice(1)[j], value: v}))].flat();
            const row = this._findRowByDimension(rowQuery);
            dataPoints.push({
                id: this._generateId(),
                index: i,
                name: values[0],
                // context: values.slice(1).join(', '),
                context: this._needsContext() ? values.slice(1).join(', ') : null,
                color: this._colors[colorIndex],
                value: row ? this._castValueToNumber(this._valueMap, this._getDataPointValue(this._valueMap, row)) : 0,
            });
            colorIndex++;
        });

        return dataPoints;
    }

    /**
     * Returns true if a context is needed. A context is need when the 3rd dimension has more than one value option
     * @private
     */
    private _needsContext(): boolean {
        return this._getDimensionsValuesCartesianProduct(this.dimension.slice(2)).length > 1;
    }

}

