
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import BaseLayout from '@/views/BaseLayout.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import SegmentContext from '@/components/SegmentContext.vue';
import DateContext from '@/components/DateContext.vue';
import Panel from '@/components/Panel.vue';
import LineChart from '@/components/Chart/LineChart.vue';
import PieChart from '@/components/Chart/PieChart.vue';
import MultiPieChart from '@/components/Chart/MultiPieChart.vue';
import BarChart from '@/components/Chart/BarChart.vue';
import TableChart from '@/components/Chart/TableChart.vue';
import DimensionTable from '@/components/DimensionTable.vue';
import KpiList from '@/components/KpiList.vue';
import IconButton from '@/components/IconButton.vue';
import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
import {SeriesData, PieSeriesData, BarSeriesData} from '@ticketengine/chart';
import {CartesianSeriesBuilder} from "@/util/CartesianSeriesBuilder";
import {MapType, SeriesBuilder, ValueMap, ValueType} from "@/util/SeriesBuilder";
import {BarSeriesBuilder} from '@/util/BarSeriesBuilder';
import {KpiSummariesBuilder} from "@/util/KpiSummariesBuilder";
import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
import queryResponseValidator from '@/util/QueryResponseValidator';
import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from "@/models/date-context";
import {SegmentSummery} from '@/models/segment';
import {KpiSummery} from '@/models/kpi';
import {PieSeriesBuilder} from "@/util/PieSeriesBuilder";
import exportService from '@/services/ExportService';
import timeSeriesInterval from '@/services/TimeSeriesInterval';
import loading from '@/util/Loading';
import {DimensionTableRow} from '@/models/table';
import {DimensionTableSeriesBuilder} from '@/util/DimensionTableSeriesBuilder';
const namespace: string = 'dashboard';

@Component({
  components: {
    BaseLayout,
    ViewHeader,
    SegmentContext,
    DateContext,
    Panel,
    LineChart,
    PieChart,
    MultiPieChart,
    BarChart,
    TableChart,
    DimensionTable,
    KpiList,
    IconButton,
  }
})
export default class AudienceOverview extends Vue {
  @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
  @Getter('dateDimensionName') dateDimensionName?: string;
  @Getter('segments') segments?: SegmentSummery[];
  @Getter('selectedSegments') selectedSegments?: SegmentSummery[];
  @Getter('periods') periods?: Period[];
  @Action('fetchPeriods') fetchPeriods: any;
  @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
  @Action('fetchDateContext') fetchDateContext: any;

  @Getter('kpi', { namespace }) kpi?: AnalyticsApiQueryResponse|null;
  @Getter('timeSeries', { namespace }) timeSeries?: AnalyticsApiQueryResponse|null;
  @Getter('topEvents', { namespace }) topEvents?: AnalyticsApiQueryResponse|null;
  @Getter('salesChannel', { namespace }) salesChannel?: AnalyticsApiQueryResponse|null;
  @Action('fetchKpi', {namespace}) fetchKpi: any;
  @Action('fetchTimeSeries', {namespace}) fetchTimeSeries: any;
  @Action('fetchTopEvents', {namespace}) fetchTopEvents: any;
  @Action('fetchSalesChannel', {namespace}) fetchSalesChannel: any;

  private timeSeriesMetric: string = 'te.sold';
  private timeSeriesDimension: string = 'te.transactionDate';

  get isLoading(): boolean {
    return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
  }

  get primaryKpi(): KpiSummery[] {
    if(this.kpi && queryResponseValidator.receivedData(this.kpi) && this.segments && this.dateContext) {
      const metrics = ['te.sold', 'te.revenue', 'te.transactionValue', 'te.uniqueCustomers'];
      const kpiBuilder = new KpiSummariesBuilder(this.kpi, this.segments, metrics, this.dateContext);
      return kpiBuilder.build();
    }
    return [];
  }

  public onSelectTimeSeriesMetric(metric: string): void {
    this.timeSeriesMetric = metric;
    this.fetchTimeSeriesData();
  }

  public fetchData(): void {
    this.fetchKpi();
    this.fetchTimeSeriesData();
    this.fetchTopEvents();
    this.fetchSalesChannel();
  }

  public fetchTimeSeriesData(): void {
    this.fetchTimeSeries({timeDimension: this.timeSeriesDimension, metric: this.timeSeriesMetric});
  }

  public exportToCsv(data: AnalyticsApiQueryResponse, dimension: string[], metrics: string[]): void {
    if(data && this.segments && this.dateDimensionName) {
      const builder = new CsvSeriesBuilder(data, this.segments, dimension, metrics, this.periods, this.dateContext);
      exportService.csv(builder.build(), 'export.csv');
    }
  }

  @Watch('dateContext')
  dateContextChanged(newDateContext: DateContextPeriod|DateContextEvent|DateContextTransaction) {
    this.timeSeriesDimension = timeSeriesInterval.getTransactionInterval(newDateContext);
    // this.fetchData();
  }

  async created() {
    await this.fetchSegmentSummaries();
    await this.fetchPeriods();
    this.fetchDateContext();
    this.fetchData();
  }


}
