
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import BaseLayout from '@/views/BaseLayout.vue';
    import ViewHeader from '@/components/ViewHeader.vue';
    import SegmentContext from '@/components/SegmentContext.vue';
    import DateContext from '@/components/DateContext.vue';
    import Panel from '@/components/Panel.vue';
    import HeatMapChart from '@/components/Chart/HeatMapChart.vue';
    import {CartesianSeriesBuilder} from "@/util/CartesianSeriesBuilder";
    import {DateContextEvent, DateContextPeriod, DateContextTransaction, Period} from "@/models/date-context";
    import {SegmentSummery} from '@/models/segment';
    import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
    import {SeriesData} from '@ticketengine/chart';
    import {MapType, ValueMap, ValueType} from "@/util/SeriesBuilder";
    import loading from '@/util/Loading';
    const namespace: string = 'event';

    @Component({
        components: {
            BaseLayout,
            ViewHeader,
            SegmentContext,
            DateContext,
            Panel,
            HeatMapChart,
        }
    })
    export default class VisitTimeView extends Vue {
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Getter('segments') segments?: SegmentSummery[];
        @Getter('periods') periods?: Period[];
        @Getter('visitTimeData', { namespace }) visitTimeData?: AnalyticsApiQueryResponse|null;
        @Action('fetchPeriods') fetchPeriods: any;
        @Action('fetchDateContext') fetchDateContext: any;
        @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        @Action('fetchVisitTimeData', {namespace}) fetchVisitTimeData: any;

        get isLoading(): boolean {
            return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
        }


        get xValuesVisitTimeSeries(): string[] {
            return ['Mo', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        }

        get yValuesVisitTimeSeries(): string[] {
            return ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'].reverse();
        }

        get visitTimeSeries(): SeriesData[] {
            if(this.visitTimeData && this.segments && this.periods) {
                const valueMapX: ValueMap = {mapType: MapType.dimension, valueType: ValueType.string, field: 'te.eventStartDayOfWeek'};
                const valueMapY: ValueMap = {mapType: MapType.dimension, valueType: ValueType.string, field: 'te.eventStartHour'};
                const valueMapZ: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.sold'};
                const seriesBuilder = new CartesianSeriesBuilder(this.visitTimeData, this.segments, this.periods, this.dateContext, valueMapX, valueMapY, valueMapZ);
                const series = seriesBuilder.build();

                // map values
                return series.map((s) => {
                    s.unit = String(this.$t('metricsAndDimensions.name.te.sold')).toLowerCase();
                    s.data = s.data.map((d) => {
                        d.x = String(this.$t('time.dayOfWeekNameAbbreviation.' + String(d.x))); // map day of week names
                        d.y = d.y + ':00';
                        return d;
                    });
                    return s;
                });
            }
            return [];
        }


        public getPanelTitle(series: SeriesData): string {
            const context = series.context ? ' - ' + series.context : '';
            return series.name + context;
        }


        public fetchData(): void {
            this.fetchVisitTimeData();
        }


        async mounted() {
            await this.fetchSegmentSummaries();
            await this.fetchPeriods();
            this.fetchDateContext();
            this.fetchData();
        }

    }
