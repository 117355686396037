import moment from 'moment';
import {
    DateContextEvent,
    DateContextPeriod,
    DateContextTransaction, instanceOfDateContextEvent,
    instanceOfDateContextPeriod, instanceOfDateContextTransaction
} from '@/models/date-context';

class TimeSeriesInterval {

    public getTransactionInterval(dateContext: DateContextPeriod|DateContextEvent|DateContextTransaction): string {
        // if(instanceOfDateContextPeriod(dateContext)) {
        //
        // }
        // if(instanceOfDateContextEvent(dateContext)) {
        //
        // }
        if(instanceOfDateContextTransaction(dateContext)) {
            const diffInDays = moment(dateContext.range.max).diff(moment(dateContext.range.min), 'days');
//             if(diffInDays < 35) {
//                 return 'te.transactionDate';
//             }
//             if(diffInDays < 400) {
//                 return 'te.transactionMonth';
//             }
// console.log(diffInDays);
        }
        return 'te.transactionDate';
    }

}

export default new TimeSeriesInterval();
