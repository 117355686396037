
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import BaseLayout from '@/views/BaseLayout.vue';
    import ViewHeader from '@/components/ViewHeader.vue';
    import SegmentContext from '@/components/SegmentContext.vue';
    import DateContext from '@/components/DateContext.vue';
    import Panel from '@/components/Panel.vue';
    import DimensionTable from '@/components/DimensionTable.vue';
    import IconButton from '@/components/IconButton.vue';
    import {DateContextEvent, DateContextPeriod, DateContextTransaction, instanceOfDateContextPeriod, instanceOfDateContextTransaction, Period} from "@/models/date-context";
    import {SegmentSummery} from '@/models/segment';
    import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
    import {DimensionTableDimension, DimensionTableLink, DimensionTableMetric, DimensionTableRow} from "@/models/table";
    import {DimensionTableSeriesBuilder} from '@/util/DimensionTableSeriesBuilder';
    import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
    import {FormatterFactory} from '@/util/FormatterFactory';
    import exportService from '@/services/ExportService';
    import loading from '@/util/Loading';
    const namespace: string = 'event';

    @Component({
        components: {
            BaseLayout,
            ViewHeader,
            SegmentContext,
            DateContext,
            Panel,
            DimensionTable,
            IconButton,
        }
    })
    export default class EventCategoryView extends Vue {
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Getter('selectedSegments') selectedSegments?: SegmentSummery[];
        @Getter('periods') periods?: Period[];
        @Getter('eventCategoriesData', { namespace }) eventCategoriesData?: AnalyticsApiQueryResponse|null;
        @Action('fetchPeriods') fetchPeriods: any;
        @Action('fetchDateContext') fetchDateContext: any;
        @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        @Action('fetchEventCategoriesData', {namespace}) fetchEventCategoriesData: any;

        get isLoading(): boolean {
            return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
        }

        get dimensions(): DimensionTableDimension[] {
            const dimensions: DimensionTableDimension[] = [];
            dimensions.push({name: 'te.eventCategory', label: ''});
            if(this.dateContext && instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
                dimensions.push({name: 'te.period', label: this.$t('metricsAndDimensions.name.te.period')});
            }
            if(this.dateContext && instanceOfDateContextTransaction(this.dateContext) && this.dateContext.compareTo.length > 0) {
                dimensions.push({name: 'te.dateRange', label: this.$t('metricsAndDimensions.name.te.dateRange')});
            }
            if(this.selectedSegments && this.selectedSegments.length > 1) {
                dimensions.push({name: 'te.segment', label: this.$t('metricsAndDimensions.name.te.segment')});
            }
            return dimensions;
        }

        get metrics(): DimensionTableMetric[] {
            const metrics = ['te.sold', 'te.eventCapacity', 'te.eventOccupancyRate', 'te.revenue', 'te.salesVelocityDays', 'te.uniqueCustomers'];
            return metrics.map((m) => {
                return {name: m, formatter: FormatterFactory.create(m),label: this.$t('metricsAndDimensions.name.' + m), description: this.$t('metricsAndDimensions.description.' + m)};
            });
        }

        get rows(): DimensionTableRow[] {
            if(this.eventCategoriesData && this.selectedSegments) {
                const builder = new DimensionTableSeriesBuilder(this.eventCategoriesData, this.selectedSegments, this.dimensions.map(d => d.name), this.metrics.map(m => m.name), this.periods, this.dateContext);
                return builder.build();
            }
            return [];
        }

        public exportToCsv(): void {
            if(this.eventCategoriesData && this.selectedSegments) {
                const builder = new CsvSeriesBuilder(this.eventCategoriesData, this.selectedSegments, this.dimensions.map(d => d.name), this.metrics.map(m => m.name), this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event-category.csv');
            }
        }

        public fetchData(): void {
            this.fetchEventCategoriesData();
        }

        async mounted() {
            await this.fetchSegmentSummaries();
            await this.fetchPeriods();
            this.fetchDateContext();
            this.fetchData();
        }

    }
