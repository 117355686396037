
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Action, Getter } from 'vuex-class';
    import {BarSeriesData} from '@ticketengine/chart';
    import BaseLayout from '@/views/BaseLayout.vue';
    import ViewHeader from '@/components/ViewHeader.vue';
    import SegmentContext from '@/components/SegmentContext.vue';
    import DateContext from '@/components/DateContext.vue';
    import Panel from '@/components/Panel.vue';
    import IconButton from '@/components/IconButton.vue';
    import KpiList from '@/components/KpiList.vue';
    import LineChart from '@/components/Chart/LineChart.vue';
    import RadarChart from '@/components/Chart/RadarChart.vue';
    import BarChart from '@/components/Chart/BarChart.vue';
    import DimensionTable from '@/components/DimensionTable.vue';
    import { RadarSeriesData } from '@ticketengine/chart';
    import {AnalyticsApiQueryResponse} from '@/models/analytics-api';
    import {RadarSeriesBuilder} from '@/util/RadarSeriesBuilder';
    import {SegmentSummery} from '@/models/segment';
    import {
        DateContextEvent,
        DateContextPeriod,
        DateContextTransaction, instanceOfDateContextPeriod,
        instanceOfDateContextTransaction,
        Period
    } from "@/models/date-context";
    import {MapType, ValueMap, ValueType} from "@/util/SeriesBuilder";
    import {
      DimensionTableDimension,
      DimensionTableLink,
      DimensionTableMetadataColumn,
      DimensionTableMetric,
      DimensionTableRow
    } from '@/models/table';
    import {DimensionTableSeriesBuilder} from '@/util/DimensionTableSeriesBuilder';
    import {BarSeriesBuilder} from '@/util/BarSeriesBuilder';
    import {FormatterFactory} from '@/util/FormatterFactory';
    import {CartesianSeriesBuilder} from '@/util/CartesianSeriesBuilder';
    import {SeriesData} from '@ticketengine/chart';
    import {KpiSummariesBuilder} from '@/util/KpiSummariesBuilder';
    import {CsvSeriesBuilder} from '@/util/CsvSeriesBuilder';
    import {KpiSummery} from '@/models/kpi';
    import exportService from '@/services/ExportService';
    import loading from '@/util/Loading';
    import {FormatDateString} from '@/util/Formatter';
    import dateService from '@/services/DateService';
    import DatePicker from '@/components/Input/DatePicker.vue';
    const namespace: string = 'event';

    @Component({
        components: {
            BaseLayout,
            ViewHeader,
            SegmentContext,
            DateContext,
            Panel,
            IconButton,
            KpiList,
            LineChart,
            RadarChart,
            BarChart,
            DimensionTable,
            DatePicker,
        }
    })
    export default class EventOverview extends Vue {
        @Getter('periods') periods!: Period[];
        @Getter('selectedSegments') selectedSegments!: SegmentSummery[];
        @Getter('dateContext') dateContext?: DateContextPeriod|DateContextEvent|DateContextTransaction;
        @Getter('overviewKpiData', { namespace }) overviewKpiData?: AnalyticsApiQueryResponse|null;
        @Getter('overviewTimeSeriesData', { namespace }) overviewTimeSeriesData?: AnalyticsApiQueryResponse|null;
        @Getter('overviewTopEventsData', { namespace }) overviewTopEventsData?: AnalyticsApiQueryResponse|null;
        @Getter('overviewSalesPerEventCategoryData', { namespace }) overviewSalesPerEventCategoryData?: AnalyticsApiQueryResponse|null;
        @Getter('overviewOccupancyPerEventCategoryData', { namespace }) overviewOccupancyPerEventCategoryData?: AnalyticsApiQueryResponse|null;
        @Action('fetchOverviewKpiData', { namespace }) fetchOverviewKpiData: any;
        @Action('fetchOverviewTimeSeriesData', { namespace }) fetchOverviewTimeSeriesData: any;
        @Action('fetchOverviewTopEventsData', { namespace }) fetchOverviewTopEventsData: any;
        // @Action('fetchOverviewSalesPerEventCategoryData', { namespace }) fetchOverviewSalesPerEventCategoryData: any;
        // @Action('fetchOverviewOccupancyPerEventCategoryData', { namespace }) fetchOverviewOccupancyPerEventCategoryData: any;
        @Action('fetchPeriods') fetchPeriods: any;
        @Action('fetchDateContext') fetchDateContext: any;
        @Action('fetchSegmentSummaries') fetchSegmentSummaries: any;
        private timeSeriesMetric: string = 'te.sold';
        private timeSeriesDimension: string = 'te.transactionDate';
        private eventDateFilter: Date|null = null;

        get isLoading(): boolean {
            return loading.isLoadingJobType(['fetch-report', 'fetch-segment', 'fetch-period']);
        }

        get primaryKpi(): KpiSummery[] {
            if(this.overviewKpiData && this.selectedSegments && this.dateContext) {
                const metrics = ['te.sold', 'te.revenue', 'te.uniqueCustomers', 'te.groupSize'];
                const kpiBuilder = new KpiSummariesBuilder(this.overviewKpiData, this.selectedSegments, metrics, this.dateContext);
                return kpiBuilder.build();
            }
            return [];
        }

        // get lineChartSeries(): SeriesData[] {
        //     if(this.overviewTimeSeriesData && this.selectedSegments && this.periods) {
        //         const valueMapX: ValueMap = {mapType: MapType.dimension, valueType: ValueType.date, field: this.timeSeriesDimension};
        //         const valueMapY: ValueMap = {mapType: MapType.metric, valueType: ['te.revenue', 'te.eventOccupancyRate'].includes(this.timeSeriesMetric) ? ValueType.float : ValueType.int, field: this.timeSeriesMetric};
        //         const seriesBuilder = new CartesianSeriesBuilder(this.overviewTimeSeriesData, this.selectedSegments, this.periods, this.dateContext, valueMapX, valueMapY);
        //         return seriesBuilder.build();
        //     }
        //     return [];
        // }


        // get salesPerEventCategory(): RadarSeriesData[] {
        //     if(this.overviewSalesPerEventCategoryData) {
        //         const valueMap: ValueMap = {mapType: MapType.metric, valueType: ValueType.int, field: 'te.sold'};
        //         const axisMap: ValueMap = {mapType: MapType.dimension, valueType: ValueType.string, field: 'te.eventCategory'};
        //         const builder = new RadarSeriesBuilder(this.overviewSalesPerEventCategoryData, this.selectedSegments, this.periods, this.dateContext, valueMap, axisMap);
        //         return builder.build();
        //     }
        //     return []
        // }
        //
        // get occupancyPerEventCategory(): BarSeriesData[] {
        //     if(this.overviewOccupancyPerEventCategoryData && this.selectedSegments && this.periods) {
        //         const valueMap: ValueMap = {mapType: MapType.metric, valueType: ValueType.float, field: 'te.eventOccupancyRate'};
        //         const builder = new BarSeriesBuilder(this.overviewOccupancyPerEventCategoryData, valueMap, ['te.eventCategory', 'te.segment'], this.selectedSegments, this.periods, this.dateContext);
        //         return builder.build();
        //     }
        //     return [];
        // }

        get topEventsRows(): DimensionTableRow[] {
            if(this.overviewTopEventsData) {
                const dimensions = this.topEventsDimensions.map(d => d.name);
                const metrics = this.topEventsMetrics.map(m => m.name);
                const builder = new DimensionTableSeriesBuilder(this.overviewTopEventsData, this.selectedSegments, dimensions, metrics, this.periods, this.dateContext, this.topEventsMetadata);
                return builder.build();
            }
            return [];
        }

        get topEventsDimensions(): DimensionTableDimension[] {
            const dimensions: DimensionTableDimension[] = [];
            // dimensions.push({name: 'te.eventOutline', label: ''});
            dimensions.push({name: 'te.eventTitle', label: ''});
            // dimensions.push({name: 'te.eventLocation', label: ''});
            // dimensions.push({name: 'te.eventStartDate', label: ''});
            if(this.dateContext && instanceOfDateContextPeriod(this.dateContext) && this.dateContext.compareTo.length > 0) {
                dimensions.push({name: 'te.period', label: this.$t('metricsAndDimensions.name.te.period')});
            }
            if(this.dateContext && instanceOfDateContextTransaction(this.dateContext) && this.dateContext.compareTo.length > 0) {
                dimensions.push({name: 'te.dateRange', label: this.$t('metricsAndDimensions.name.te.dateRange')});
            }
            if(this.selectedSegments && this.selectedSegments.length > 1) {
                dimensions.push({name: 'te.segment', label: this.$t('metricsAndDimensions.name.te.segment')});
            }
            return dimensions;
        }

        get topEventsMetrics(): DimensionTableMetric[] {
            // const paymentMethodMetrics = ['te.sold', 'te.revenue', 'te.eventOccupancyRate'];
            const metrics = ['te.eventSold', 'te.eventReserved', 'te.eventCapacity', 'te.eventOccupancyRate', 'te.eventRevenue', 'te.salesVelocityDays'];
            return metrics.map((m) => {
                return {name: m, formatter: FormatterFactory.create(m),label: this.$t('metricsAndDimensions.name.' + m), description: this.$t('metricsAndDimensions.description.' + m)};
            });
        }

        get topEventsMetadata(): string[] {
            // return ['te.eventId'];
            return ['te.eventId', 'te.eventLocation', 'te.eventStartDateHourMinute']; // dimensions that need to be added as metadata
        }

        get topEventsMetadataColumns(): DimensionTableMetadataColumn[] {
            const dateFormatter = new FormatDateString('YYYYMMDDHHmm', 'MMM D YYYY, HH:mm');
            return [
              {name: 'te.eventLocation', label: this.$t('metricsAndDimensions.name.te.eventLocation')},
              {name: 'te.eventStartDateHourMinute', label: this.$t('metricsAndDimensions.name.te.eventStartDate'), formatter: dateFormatter}
            ]; // metadata that needs to be displayed in a column
        }

        get topEventsTableLink(): DimensionTableLink {
            return {
                routeName: 'event-detail',
                params: [{paramKey: 'id', metadata: 'te.eventId'}]
            }
        }

        public onSelectTimeSeriesMetric(metric: string): void {
            this.timeSeriesMetric = metric;
            this.getTimeSeriesData();
        }

        public getTimeSeriesData(): void {
            this.fetchOverviewTimeSeriesData({timeDimension: this.timeSeriesDimension, metric: this.timeSeriesMetric});
        }

        public exportTimeSeriesToCsv(): void {
            if(this.overviewTimeSeriesData && this.selectedSegments) {
                const builder = new CsvSeriesBuilder(this.overviewTimeSeriesData, this.selectedSegments, [this.timeSeriesDimension, 'te.segment', 'te.period'], [this.timeSeriesMetric], this.periods, this.dateContext);
                exportService.csv(builder.build(), 'event-time-series.csv');
            }
        }

        public exportEventList(): void {
          if(this.overviewTopEventsData && this.selectedSegments) {
            const builder = new CsvSeriesBuilder(this.overviewTopEventsData, this.selectedSegments, ['te.eventTitle', 'te.eventLocation', 'te.eventStartDateHourMinute', 'te.segment', 'te.period'], ['te.eventSold', 'te.eventReserved', 'te.eventCapacity', 'te.eventOccupancyRate', 'te.eventRevenue', 'te.salesVelocityDays'], this.periods, this.dateContext);
            exportService.csv(builder.build(), 'events.csv');
          }
        }

        // public exportSalesPerEventCategoryToCsv(): void {
        //     if(this.overviewSalesPerEventCategoryData && this.selectedSegments) {
        //         const builder = new CsvSeriesBuilder(this.overviewSalesPerEventCategoryData, this.selectedSegments, ['te.eventCategory', 'te.segment', 'te.period'], ['te.sold'], this.periods, this.dateContext);
        //         exportService.csv(builder.build(), 'sales-per-category.csv');
        //     }
        // }
        //
        // public exportOccupancyPerEventCategoryToCsv(): void {
        //     if(this.overviewOccupancyPerEventCategoryData && this.selectedSegments) {
        //         const builder = new CsvSeriesBuilder(this.overviewOccupancyPerEventCategoryData, this.selectedSegments, ['te.eventCategory', 'te.segment', 'te.period'], ['te.eventOccupancyRate'], this.periods, this.dateContext);
        //         exportService.csv(builder.build(), 'occupancy-per-category.csv');
        //     }
        // }

        public setEventDateFilter(d: Date|null): void {
          this.eventDateFilter = d;
          this.fetchData();
        }

        public fetchData(): void {
          const filter = {eventStartDate: this.eventDateFilter ? dateService.formatDate(this.eventDateFilter, 'YYYYMMDD') : null};
          this.fetchOverviewKpiData();
          this.fetchOverviewTopEventsData(filter);
          // this.fetchOverviewSalesPerEventCategoryData();
          // this.fetchOverviewOccupancyPerEventCategoryData();
          this.getTimeSeriesData();
        }

        async mounted() {
            await this.fetchSegmentSummaries();
            await this.fetchPeriods();
            this.fetchDateContext();
            this.fetchData();
        }


    }
